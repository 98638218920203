<template>
  <div v-if="coupon">
    <v-card outlined>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h5">
            {{ coupon.code }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ coupon.description }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-row dense>
            <v-col cols="auto">
              <v-btn
                color="primary"
                depressed
                @click="showSendCouponDialog"
              >
                {{ $t('send') }}
                <v-icon
                  right
                >
                  mdi-email
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-list-item-action>
      </v-list-item>

      <v-tabs v-model="selectedTab">
        <v-tab href="#stats">
          {{ $t('stats') }}
        </v-tab>
        <v-tab href="#form">
          {{ $t('form') }}
        </v-tab>
        <v-tab href="#redemptions">
          {{ $t('redemptions') }}
        </v-tab>
      </v-tabs>
    </v-card>
    <v-tabs-items
      class="transparent"
      v-model="selectedTab"
    >
      <v-tab-item
        v-for="tab in tabs"
        :value="tab.key"
        :key="tab.key"
      >
        <v-row v-if="tab.key === 'stats' && coupon !== null && coupon !== undefined">
          <v-col cols="12">
            <FilterHeader
              use-date
              @newValues="updateFilters($event)"
            />
          </v-col>
          <v-col
            cols="12"
            md="12"
          >
            <TransientDash
              :key="coupon.uuid + ':sales:' + startTimeInMillis + ':' + endTimeInMillis"
              :title="$t('sales')"
              namespace="coupon_aggregate_value"
              :config="couponValueConfig"
              :start-time-in-millis="startTimeInMillis"
              :end-time-in-millis="endTimeInMillis"
            />
          </v-col>
          <v-col
            cols="12"
            md="12"
          >
            <TransientDash
              :key="coupon.uuid + ':coupon_usage_aggregate_value:' + startTimeInMillis + ':' + endTimeInMillis"
              :title="$t('Coupon Usage')"
              namespace="coupon_usage_aggregate_value"
              :config="couponValueConfig"
              :start-time-in-millis="startTimeInMillis"
              :end-time-in-millis="endTimeInMillis"
            />
          </v-col>
        </v-row>
        <CouponRedemptions
          class="mt-2"
          :coupon="coupon"
          v-if="tab.key === 'redemptions'"
        />
        <CouponForm
          class="mt-2"
          v-if="tab.key === 'form'"
          v-model="coupon"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import FilterHeader from '@/components/universalFilter/FilterHeader.vue'
import CouponRedemptions from "@/components/coupon/CouponRedemptions"
import CouponForm from "@/components/CouponForm"
import TransientDash from '@/components/dash/TransientDash.vue'

export default {
    name: 'CouponProfile',
    props: {
      coupon: {
        type: Object,
        default: () => null
      }
    },
    components: {
        CouponRedemptions,
        CouponForm,
				FilterHeader,
				TransientDash
    },
    data() {
        return {
            selectedTab: null
        }
    },
    methods: {
			updateFilters(filters) {
        this.startTimeInMillis = filters.startTimeInMillis
        this.endTimeInMillis = filters.endTimeInMillis
      },
      showSendCouponDialog() {
        this.$store.commit('updateGenericDialog', {
        'component': 'components/coupon/SendCouponCard',
        'props': {
          'coupon': this.coupon
        }
      })
      }
    },
    computed: {
				couponValueConfig() {

					if(this.coupon === null || this.coupon === undefined) {
						return {}
					}

					return {
						couponUUID: this.coupon.uuid
					}
				},
				startTimeInMillis: {
					get() {
						return this.$store.state.profileStatsStartTimeInMillis
					},
					set(value) {
						this.$store.commit('updateProfileStatsStartTimeInMillis', value)
					}
				},
      	endTimeInMillis: {
					get() {
						return this.$store.state.profileStatsEndTimeInMillis
					},
					set(value) {
						this.$store.commit('updateProfileStatsEndTimeInMillis', value)
					}
      	},
        tabs() {
            return [
								{
                    key: 'stats',
                    label: this.$t('stats')
                },
                {
                    key: 'form',
                    label: this.$t('form')
                },
                {
                    key: 'redemptions',
                    label: this.$t('redemptions')
                }
            ]
        }
    }
}
</script>

<template>
  <div style="height: 100%;">
    <ActionList 
      :load-items-callback="loadItemsCallback"
      :filters="filters"
      :fixed-filters="fixedFilters"
      :call-to-actions="callToActions"
    >
      <template v-slot:item="{ item, index }">
        <CouponListItem
          :coupon="item"
          :key="item.uuid"
          @selectCoupon="$emit('selectCoupon', item)"
        />
        <v-divider
          inset
          :key="index"
        />
      </template>
    </ActionList>
  </div>
</template>

<script>
  import CouponListItem from "./CouponListItem"
  import ActionList from "./common/list/ActionList"
  export default {
    name: 'CouponList',
    props: {
      loadItemsCallback: {
        type: Function, 
        default: () => null
      },
      viewList: {
        type: Boolean
      }, 
      fixedFilters: {
        type: Array, 
        default: () => []
      },
      callToActions: {
        type: Array,
        default: () => []
      }
    },
    components: {
      CouponListItem,
      ActionList
    },
    data()  { 
      return {
        startIndex: 0,
        currentLength: 0,
        isLoadingMore: false,
        filters: [
          {
            key: 'customer.address.name_line',
            label: this.$t('customerName'),
            type: 'text',
            value: null,
            operator: 'contains'
          }, 
          {
            key: 'item.title',
            label: this.$t('item'),
            type: 'text',
            value: null,
            operator: 'contains'
          }
        ]
      }
    },
    computed: {
      slicedCoupons() {
        return this.coupons.slice(0, this.currentLength)
      }
    },
    methods: {
      loadMore: function() {
        this.isLoadingMore = true
        this.currentLength = this.currentLength + this.step
      }
    },
    mounted() {
      this.currentLength = this.length
    }
  }
</script>

<template>
  <v-list-item
    clickable
    @click="$emit('selectCoupon', coupon)"
  >
    <v-list-item-content>
      <v-list-item-title>
        <span>{{ coupon.label }}</span>
      </v-list-item-title>
      <v-list-item-subtitle style="padding: 4px 0">
        <span
          v-if="coupon.customer && coupon.item && coupon.item.title"
          class="text--primary"
        >
          {{ coupon.item.title }}
        </span>
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        <span class="text--primary">{{ coupon.code }}</span> &mdash;
        <span v-if="coupon.customer && coupon.customer.address && coupon.customer.address.name_line">{{ coupon.customer.address.name_line }}</span>
        <span v-else>{{ coupon.description }}</span>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-progress-circular :value="value">
        {{ coupon.timesRedeemed }}
      </v-progress-circular>
    </v-list-item-action>
    <v-list-item-action>
      <v-btn
        target="_blank"
        :href="'https://coupons.salescloud.is?uuid=' + coupon.uuid + '&organization=' + organization.uuid"
        icon
      >
        <v-icon>mdi-link</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
  export default {
    name: 'CouponListItem',
    props: {
      coupon: {
        type: Object, 
        default: () => {}
      }
    },
    computed: {
      value() {
        return (this.coupon.timesRedeemed / this.coupon.maxRedemptions) * 100
      },
      organization() {
        return this.$store.state.organization
      },
      primaryColor() {
        return this.$store.getters.primaryColor
      },
    }
  }
</script>

<template>
  <v-tabs-items
    v-model="selectedAppBarTab"
    style="height: 100%;"
  >
    <v-tab-item
      v-for="tab in tabs"
      :value="tab.key"
      :key="tab.key"
      style="height: 100%;"
    >
      <v-row style="height: 100vh;">
        <v-col
          class="pr-0 pt-0"
          cols="12"
          md="4"
          style="border-right: 1px solid #ddd;"
        >
          <v-card
            tile
            flat
            max-height="100vh"
            class="overflow-y-auto"
          >
            <CouponList
              class="pt-5"
              v-if="tab.key === 'active'"
              :length="limit"
              :step="limit"
              @selectCoupon="selectCoupon"
              :load-items-callback="getCoupons"
              :fixed-filters="activeFixedFilters"
            />
            <CouponList
              class="pt-5"
              v-if="tab.key === 'inactive'"
              :length="limit"
              :step="limit"
              @selectCoupon="selectCoupon"
              :load-items-callback="getCoupons"
              :fixed-filters="inactiveFixedFilters"
            />
            <CouponList
              class="pt-5"
              v-if="tab.key === 'fully_redeemed'"
              :length="limit"
              :step="limit"
              @selectCoupon="selectCoupon"
              :load-items-callback="getCoupons"
              :fixed-filters="fullyRedeemedFixedFilters"
            />
            <CouponList
              class="pt-5"
              v-if="tab.key === 'unused'"
              :length="limit"
              :step="limit"
              @selectCoupon="selectCoupon"
              :load-items-callback="getCoupons"
              :fixed-filters="unusedFixedFilters"
            />
          </v-card>
        </v-col>
        <v-col
          class="grey lighten-3"
          cols="8"
          v-if="!$vuetify.breakpoint.xsOnly"
        >
          <CouponProfile
            class="pr-3 overflow-y-auto"
            style="max-height: 100vh"
            :coupon="selectedCoupon"
          />
        </v-col>
      </v-row>
    </v-tab-item>
    <v-bottom-sheet
      v-if="$vuetify.breakpoint.xsOnly"
      v-model="showBottomSheet"
    >
      <CouponProfile
        class="grey lighten-3 mb-3 overflow-y-auto"
        :coupon="selectedCoupon"
        style="max-height: 100vh"
      />
    </v-bottom-sheet>
    <CouponDialog
      v-model="addNewCoupon"
      v-if="addNewCoupon"
      @closed="dialogClosed"
      @couponCreated="couponCreated"
    />
  </v-tabs-items>
</template>

<script>

  import CouponList from "@/components/CouponList"
  import CouponDialog from "@/components/CouponDialog"
  import CouponProfile from "@/components/coupon/CouponProfile"

  export default {
    name: 'Coupon',
    components: {
      CouponDialog,
      CouponList,
      CouponProfile
    },
    data: () => ({
      limit: 100,
      skip: 0,
      addNewCoupon: false,
      couponAdded: null,
      viewList: false,
      selectedCoupon: null,
      activeFixedFilters: [
        {
          key: 'active',
          value: true,
          operator: 'equals',
          type: 'boolean'
        },
      ],
      inactiveFixedFilters: [
        {
          key: 'active',
          value: false,
          operator: 'equals',
          type: 'boolean'
        }
      ],
      fullyRedeemedFixedFilters: [
        {
          key: 'timesRedeemed',
          dynamicValue: 'maxRedemptions',
          operator: 'equals',
          type: 'number'
        },
        {
          key: 'maxRedemptions',
          value: 0,
          operator: 'greaterThan',
          type: 'number'
        }
      ],
      unusedFixedFilters: [
        {
          key: 'timesRedeemed',
          value: 0,
          operator: 'equals',
          type: 'number'
        }
      ]
    }),
    watch: {
      coupons() {
        this.$store.commit('updateAppBarTabs', this.tabs)
      }
    },
    methods: {
      getCoupons({limit, skip, conditions}) {
        return this.$store.dispatch('getCoupons', {})
      },
      selectCoupon(coupon) {
        this.selectedCoupon = coupon
      },
      createCoupon() {
        this.addNewCoupon = !this.addNewCoupon
      },
      dialogClosed() {
        this.addNewCoupon = !this.addNewCoupon
      },
      couponCreated(payload) {
        this.dialogClosed()
        if(payload.success) {
          this.couponAdded = payload.coupon
          this.$store.commit('updateActionSuccess', {
            message: this.$t('success'),
            subMessage: this.$t('coupon') + ' ' + payload.coupon.label + ' ' + this.$t('wasAddedToYourActiveCoupons')
          })
        }
      },
      exportCoupons() {
        this.$store.commit('updateExportObjects', this.coupons)
      },
      changeView() {
        if(this.viewList) {
          this.$store.commit('updateAppBarChangeDisplay', {
            icon: 'mdi-format-list-bulleted'
          })
        } else {
          this.$store.commit('updateAppBarChangeDisplay', {
            icon: 'mdi-view-day'
          })
        }
        this.viewList = !this.viewList
      }
    },
    computed: {
      showBottomSheet: {
        get() {
          return this.selectedCoupon !== null
        },
        set(value) {
          this.selectedCoupon = null
        }
		  },
      tabs() {
        return [
          {
            title: this.$t('active'),
            key: 'active',
            badge: {
              color: 'green',
              content: this.activeCouponsCount
            }
          },
          {
            title: this.$t('inactive'),
            key: 'inactive',
            badge: {
              color: 'red',
              content: this.inactiveCouponsCount
            }
          },
          {
            title: this.$t('fullyRedeemed'),
            key: 'fully_redeemed',
            badge: {
              color: 'yellow',
              content: this.fullyRedeemedCouponsCount
            }
          },
          {
            title: this.$t('unused'),
            key: 'unused',
            badge: {
              color: 'blue',
              content: this.unusedCouponsCount
            }
          }
        ]
      },
      selectedAppBarTab: function() {
        return this.$store.state.selectedAppBarTab
      },
      activeCoupons() {
        if(this.coupons === null) {
          return null
        }

        return this.coupons.filter(coupon => coupon.active)
      },
      disabledCoupons() {
        if(this.coupons === null) {
          return null
        }

        return this.coupons.filter(coupon => !coupon.active)
      },
      unusedCoupons() {
        if(this.coupons === null) {
          return null
        }

        return this.coupons.filter(coupon => coupon.active && coupon.timesRedeemed === 0)
      },
      fullyRedeemedCoupons() {
        if(this.coupons === null) {
          return null
        }

        return this.coupons.filter(coupon => coupon.active && coupon.maxRedemptions <= coupon.timesRedeemed && coupon.maxRedemptions !== 0)
      },
      coupons: function() {
        if(this.query !== null && this.query.length > 0 ) {

          const query = this.query.reduce((accumulator, item) => accumulator + item.value, '')

          return this.$store.state.coupons.filter(function(coupon) {
            if(coupon !== null && coupon !== undefined) {

              // Checking coupon label
              if(coupon.label !== null && coupon.label !== undefined && coupon.label.toLowerCase().includes(query.toLowerCase())) {
                return true
              }

              // Checking coupon code
              if(coupon.code !== null && coupon.code !== undefined && coupon.code.toLowerCase().includes(query.toLowerCase())) {
                return true
              }

              // Checking coupon item
              if(coupon.item !== null && coupon.item !== undefined) {
                if(coupon.item.title !== null && coupon.item.title !== undefined && coupon.item.title.toLowerCase().includes(query.toLowerCase())) {
                  return true
                }
              }

              // Checking coupon customer
              if(coupon.customer !== null && coupon.customer !== undefined) {
                const customer = coupon.customer
                if(customer.address !== null && customer.address !== undefined) {
                  const customerAddress = customer.address
                  if(customerAddress.name_line !== null && customerAddress.name_line !== undefined && customerAddress.name_line.toLowerCase().includes(query.toLowerCase())) {
                    return true
                  }
                }
              }
            }

            return false
          }.bind(this))
        }

        return this.$store.state.coupons
      },
      activeCouponsCount() {
        return this.activeCoupons !== null ? this.activeCoupons.length : 0
      },
      inactiveCouponsCount() {
        return this.disabledCoupons !== null ? this.disabledCoupons.length : 0
      },
      unusedCouponsCount() {
        return this.unusedCoupons !== null ? this.unusedCoupons.length : 0
      },
      fullyRedeemedCouponsCount() {
        return this.fullyRedeemedCoupons !== null ? this.fullyRedeemedCoupons.length : 0
      },
      query() {
        return this.$store.state.searchQuery
      },
    },
    created() {
      this.$store.commit('updateAppBarTabs', this.tabs)
      this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
      this.$store.commit('updateAppBarFab', {
        color: 'pink',
        callback: this.createCoupon
      })
      this.$store.commit('updateAppBarExport', {
        color: 'pink',
        callback: this.exportCoupons
      })
      this.$store.commit('updateAppBarChangeDisplay', {
        icon: 'mdi-format-list-bulleted',
        callback: this.changeView
      })
      this.$store.dispatch('getCoupons')

    }
  }
</script>

<!--Coupons Old-->
<!--<template>
  <v-tabs-items
    v-model="selectedAppBarTab"
    style="height: 100%;"
  >
    <v-tab-item
      v-for="tab in tabs"
      :value="tab.key"
      :key="tab.key"
      style="height: 100%;"
    >
      <CouponList
        class="pt-5"
        v-if="tab.key === 'active'"
        :coupons="activeCoupons"
        :length="limit"
        :step="limit"
        :view-list="viewList"
      />
      <CouponList
        class="pt-5"
        v-if="tab.key === 'inactive'"
        :coupons="disabledCoupons"
        :length="limit"
        :step="limit"
        :view-list="viewList"
      />
      <CouponList
        class="pt-5"
        v-if="tab.key === 'unused'"
        :coupons="unusedCoupons"
        :length="limit"
        :step="limit"
        :view-list="viewList"
      />
      <CouponList
        class="pt-5"
        v-if="tab.key === 'fully_redeemed'"
        :coupons="fullyRedeemedCoupons"
        :length="limit"
        :step="limit"
        :view-list="viewList"
      />
    </v-tab-item>
    <CouponDialog
      v-model="addNewCoupon"
      v-if="addNewCoupon"
      @closed="dialogClosed"
      @couponCreated="couponCreated"
    />
  </v-tabs-items>
</template>

<script>

  import CouponList from "@/components/CouponList"
  import CouponDialog from "@/components/CouponDialog"

  export default {
    name: 'Payments',
    components: {
      CouponDialog,
      CouponList
    },
    data: () => ({
      limit: 100,
      skip: 0,
      addNewCoupon: false,
      couponAdded: null,
      viewList: false
    }),
    watch: {
      coupons() {

        this.$store.commit('updateAppBarTabs', this.tabs)
      }
    },
    methods: {
      getCoupons({limit, skip, conditions}) {
        return this.$store.dispatch('getCoupons', {})
      },
      createCoupon() {
        this.addNewCoupon = !this.addNewCoupon
      },
      dialogClosed() {
        this.addNewCoupon = !this.addNewCoupon
      },
      couponCreated(payload) {
        this.dialogClosed()
        if(payload.success) {
          this.couponAdded = payload.coupon
          this.$store.commit('updateActionSuccess', {
            message: 'Successfully created a coupon',
            subMessage: 'Coupon ' + payload.coupon.label + ' was added to your active coupons list.'
          })
        }
      },
      exportCoupons() {
        this.$store.commit('updateExportObjects', this.coupons)
      },
      changeView() {
        if(this.viewList) {
          this.$store.commit('updateAppBarChangeDisplay', {
            icon: 'mdi-format-list-bulleted'
          })
        } else {
          this.$store.commit('updateAppBarChangeDisplay', {
            icon: 'mdi-view-day'
          })
        }
        this.viewList = !this.viewList
      }
    },
    computed: {
      tabs: function() {
        return [
          {
            title: 'Active',
            key: 'active',
            badge: {
              color: 'green',
              content: this.activeCouponsCount
            }
          },
          {
            title: 'Inactive',
            key: 'inactive',
            badge: {
              color: 'red',
              content: this.inactiveCouponsCount
            }
          },
          {
            title: 'Unused',
            key: 'unused',
            badge: {
              color: 'blue',
              content: this.unusedCouponsCount
            }
          },
          {
            title: 'Fully Redeemed',
            key: 'fully_redeemed',
            badge: {
              color: 'blue',
              content: this.fullyRedeemedCouponsCount
            }
          }
        ]
      },
      selectedAppBarTab: function() {
        return this.$store.state.selectedAppBarTab
      },
      activeCoupons() {
        if(this.coupons === null) {
          return null
        }

        return this.coupons.filter(coupon => coupon.active)
      },
      disabledCoupons() {
        if(this.coupons === null) {
          return null
        }

        return this.coupons.filter(coupon => !coupon.active)
      },
      unusedCoupons() {
        if(this.coupons === null) {
          return null
        }

        return this.coupons.filter(coupon => coupon.active && coupon.timesRedeemed === 0)
      },
      fullyRedeemedCoupons() {
        if(this.coupons === null) {
          return null
        }

        return this.coupons.filter(coupon => coupon.active && coupon.maxRedemptions <= coupon.timesRedeemed && coupon.maxRedemptions !== 0)
      },
      coupons: function() {
        if(this.query !== null && this.query.length > 0 ) {

          const query = this.query.reduce((accumulator, item) => accumulator + item.value, '')

          return this.$store.state.coupons.filter(function(coupon) {
            if(coupon !== null && coupon !== undefined) {

              // Checking coupon label
              if(coupon.label !== null && coupon.label !== undefined && coupon.label.toLowerCase().includes(query.toLowerCase())) {
                return true
              }

              // Checking coupon code
              if(coupon.code !== null && coupon.code !== undefined && coupon.code.toLowerCase().includes(query.toLowerCase())) {
                return true
              }

              // Checking coupon item
              if(coupon.item !== null && coupon.item !== undefined) {
                if(coupon.item.title !== null && coupon.item.title !== undefined && coupon.item.title.toLowerCase().includes(query.toLowerCase())) {
                  return true
                }
              }

              // Checking coupon customer
              if(coupon.customer !== null && coupon.customer !== undefined) {
                const customer = coupon.customer
                if(customer.address !== null && customer.address !== undefined) {
                  const customerAddress = customer.address
                  if(customerAddress.name_line !== null && customerAddress.name_line !== undefined && customerAddress.name_line.toLowerCase().includes(query.toLowerCase())) {
                    return true
                  }
                }
              }
            }

            return false
          }.bind(this))
        }

        return this.$store.state.coupons
      },
      activeCouponsCount() {
        return this.activeCoupons !== null ? this.activeCoupons.length : 0
      },
      inactiveCouponsCount() {
        return this.disabledCoupons !== null ? this.disabledCoupons.length : 0
      },
      unusedCouponsCount() {
        return this.unusedCoupons !== null ? this.unusedCoupons.length : 0
      },
      fullyRedeemedCouponsCount() {
        return this.fullyRedeemedCoupons !== null ? this.fullyRedeemedCoupons.length : 0
      },
      query() {
        return this.$store.state.searchQuery
      },
    },
    created() {
      this.$store.commit('updateAppBarTabs', this.tabs)
      this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
      this.$store.commit('updateAppBarFab', {
        color: 'pink',
        callback: this.createCoupon
      })
      this.$store.commit('updateAppBarExport', {
        color: 'pink',
        callback: this.exportCoupons
      })
      this.$store.commit('updateAppBarChangeDisplay', {
        icon: 'mdi-format-list-bulleted',
        callback: this.changeView
      })
      this.$store.dispatch('getCoupons')
    }
  }
</script>
-->
